import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { VerticleDividerProps } from './VerticleDivider.modal';

const StyledVerticleDivider = styled.div`
  ${() => css`
    background: #f2f2f2;
    width: 1px;
    height: 48px;
  `}
`;
const VerticleDivider: FC<VerticleDividerProps> = ({
  variant = 'default',
  ...props
}) => <StyledVerticleDivider {...props} />;

export default VerticleDivider;
