import React, { FC } from 'react';
import { SimpleFooter } from '@agria/theme/src/components/footers/simpleFooter/SimpleFooter';
import { useSiteMetadata } from '@agria/theme/src/hooks/useSiteMetadata';
import BascDisclosure from '@agria/theme-basc/src/components/BascDisclosure';
import { Stack } from '@agria/theme/src/components/stack/Stack';
import { Section } from '@agria/theme/src/components/section/Section';
import { Surface } from '@agria/theme/src/components/surface/Surface';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Interface
 
export interface FooterProps {}

const StyledSection = styled.div<StyledSectionProps>`
  ${({ theme: { space }, backgroundColor }) => css`
    & > section {
      padding-top: 0;
    }
  `};
`;

// JSX

export const Footer: FC<FooterProps> = () => {
  const { footerTerms } = useSiteMetadata();
  return (
    <>  
        <StyledSection>
          <Section backgroundColor="utilityBackground">
            <BascDisclosure />
          </Section>
          </StyledSection>
        <SimpleFooter footerTerms={footerTerms} />
      </>
  )
};

export default Footer;
