import { isProd } from '@agria/utils';

const siteLinksDev = {
  brochureware: null,
  makeAPayment: null,
  onlineVetCLaims: null,
  onlineCustomerClaims: null,
  quoteAndBuy: null,
  quoteAndBuyContinueCover: 'https://agria-qab.netlify.app/continue-cover',
  freeCover: 'https://basc-breeders.netlify.app',
  rehomingCover: null,
  vetActivatedCover: null,
  breederClub: 'https://basc-breeders.netlify.app',
  vetOwnerActivated: null,
  litterDataCapture: null,
  joinBreederClub: 'https://basc-breeders.netlify.app',
  myAccount: 'https://www.agriapet.co.uk/my-account/',
};

const siteLinksProduction = {
  ...siteLinksDev,
  brochureware: 'https://www.agriapet.co.uk',
  myAccount: 'https://www.agriapet.co.uk/my-account/',
  quoteAndBuyContinueCover: 'https://quote.agriapet.co.uk/continue-cover',
  quoteAndBuyRetrieve: 'https://quote.agriapet.co.uk/retrieve',
  freeCover: 'https://basc-breeders.agriapet.co.uk',
  joinBreederClub: 'https://join-basc.agriapet.co.uk',
  breederClub: 'https://breeders.agriapet.co.uk',
};
export const siteLinks = isProd ? siteLinksProduction : siteLinksDev;
