import React, { createContext, FC } from 'react';
import { ThemeColorsKeys } from '../styles/tokens/colors';

type SectionContextProps = {
  sectionBackground?: ThemeColorsKeys | undefined;
};

export const SectionContext = createContext<Partial<SectionContextProps>>({
  sectionBackground: undefined,
});

export const SectionContextProvider: FC<any> = ({ value, children }) => (
  <SectionContext.Provider value={value}>{children}</SectionContext.Provider>
);
