import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SimpleHeader as SimpleHeaderComponent } from '@agria/theme/src/components/headers/simpleHeader/SimpleHeader';
import { useSiteMetadata } from '@agria/theme/src/hooks/useSiteMetadata';

// Interface

export interface HeaderProps {}

// Styles
const StyledWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandThree};
  `};
`;

// JSX

export const Header: FC<HeaderProps> = () => {
  const { contactNumber, companyName } = useSiteMetadata();
  return (
    <StyledWrapper>
      <SimpleHeaderComponent
        contactNumber={contactNumber}
        companyName={companyName}
      />
    </StyledWrapper>
  );
};

export default Header;
