import { Typography } from "@agria/theme/src/components/typography/Typography";
const BascDisclosure = () => {
	return (
	<Typography>
		BASC Direct Ltd is an Introducer Appointed Representative of Agria Pet
		Insurance Ltd who administer the insurance and is authorised and regulated
		by the Financial Conduct Authority, Financial Services Register Number
		496160. Agria Pet Insurance is registered and incorporated in England and
		Wales with registered number 04258783. Registered office: First Floor, Blue
		Leanie, Walton Street, Aylesbury, Buckinghamshire, HP21 7QW. Agria insurance
		policies are underwritten by Agria Försäkring.
	</Typography>);
};

export default BascDisclosure;
